<template>
  <landing-card
    :title="title"
    icon-type="el-icon-fa-bars"
    :description="description"
    :nextLink="getNextPath"
    :checkRTL="checkRTL"
  >
    <ul class="desc-list">
      <li :class="checkTextRight" v-for="i in 8" :key="i">
        <app-i18n-wl
          :code="'entities.introduction.introductions.' + [i - 1]"
          :lang="lang"
        >
        </app-i18n-wl>
      </li>
    </ul>
  </landing-card>
</template>

<script>
import { i18nWL } from '@/i18n';
export default {
  name: 'online-exam-view-page',
  props: ['id', 'lang'],
  computed: {
    checkRTL() {
      //burda exam cekilmedigi icin lokal checkRTL kullanilir
      return this.lang === 'ar' ? ' rtl ' : '';
    },
    checkTextRight() {
      //burda exam cekilmedigi icin lokal checkRTL kullanilir
      return this.lang === 'ar' ? ' text-right ' : '';
    },
    getNextPath() {
      return `/intro/${this.id}/${this.lang}`;
    },
    description() {
      return i18nWL('entities.introduction.description', this.lang);
    },
    title() {
      return i18nWL('entities.introduction.title', this.lang);
    }
  }
};
</script>

<style>
ul.desc-list > li.text-right {
  text-align: right;
}
</style>
